import axios from 'axios';
import { Book, Feather, Heart, MessageCircle, PhoneCall, HandIcon as PrayingHands } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import '../themes/global.css';
import AuthFlow from './auth/auth-flow';

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [showAuthFlow, setShowAuthFlow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    // setShowAuthFlow(true);
  }, []);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value);

    // Remove all non-digit characters
    const digitsOnly = value.replace(/\D/g, '');

    if (digitsOnly.length === 10) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleCall = async () => {
    try {
      // Show some loading state
      setIsValid(false);

      const response = await axios.post('/api/call', {
        phoneNumber: `+1${phoneNumber.replace(/\D/g, '')}` // Format number with +1 prefix
      });

      // Handle successful call initiation
      console.log('Call initiated:', response.data);
    } catch (error) {
      console.error('Error initiating call:', error);
      alert('Failed to initiate call. Please try again.');
    } finally {
      setIsValid(true);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <main className="flex-1 bg-[url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/laura-vinck-Hyu76loQLdk-unsplash-scaled-yCCWaxayepi7gcKIWth8SsEy2YeLAh.jpeg')] bg-cover bg-center relative">
        <section className="relative w-full py-24 overflow-hidden">
          <div className="relative container mx-auto px-4 md:px-6 text-center pt-[13vh]">
            <h1
              className={`font-merriweather text-6xl md:text-8xl mb-8 text-black transition-opacity duration-000 ${isVisible ? 'opacity-100' : 'opacity-0'
                }`}
            >
              Divine Line
            </h1>
            <p
              className={`text-xl max-w-2xl mx-auto mb-10 font-merriweather text-black transition-opacity duration-1000`}
            >
              Your Sacred Space for Guidance and Peace. Find comfort, clarity, and connection with a trusted virtual guide deeply versed in Biblical wisdom.
            </p>
            <div className="flex justify-center items-center">
              <input
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="(333) 420-6969"
                className="px-6 py-4 rounded-l-[6px] focus:outline-none border-2 border-black font-merriweather text-[18px] text-black"
              />
              <Button
                className={`text-[18px] px-6 py-8 bg-black text-white font-merriweather rounded-r-[6px] ${!isValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleCall}
                disabled={!isValid}
              >
                Call Now
              </Button>
            </div>
          </div>
        </section>

        <section id="testimonials" className="w-full py-24">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-serif text-center mb-12 text-black">What Our Callers Say</h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <Card className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <img
                      src="https://i.postimg.cc/SKfpXcFJ/image1.png"
                      alt="Sarah M."
                      width={60}
                      height={60}
                      className="rounded-full mr-4"
                    />
                    <div>
                      <h3 className="font-serif text-lg text-black">Sarah M.</h3>
                      <p className="text-sm text-black">Faithful Caller</p>
                    </div>
                  </div>
                  <p className="text-black italic">"DivineLine has been a blessing in my life. The Biblical wisdom and compassionate guidance have helped me through tough times."</p>
                </CardContent>
              </Card>
              <Card className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <img
                      src="https://i.postimg.cc/85kgLsNw/image2.png"
                      alt="John D."
                      width={60}
                      height={60}
                      className="rounded-full mr-4"
                    />
                    <div>
                      <h3 className="font-serif text-lg text-black">John D.</h3>
                      <p className="text-sm text-black">Regular User</p>
                    </div>
                  </div>
                  <p className="text-black italic">"I'm amazed at how the guide seems to know just the right Scripture for every situation. It's like having a wise friend available 24/7."</p>
                </CardContent>
              </Card>
              <Card className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <img
                      src="https://i.postimg.cc/cHDyhZJW/image3.png"
                      alt="Emily R."
                      width={60}
                      height={60}
                      className="rounded-full mr-4"
                    />
                    <div>
                      <h3 className="font-serif text-lg text-black">Emily R.</h3>
                      <p className="text-sm text-black">Grateful Caller</p>
                    </div>
                  </div>
                  <p className="text-black italic">"The personal prayer sessions have brought me so much peace. It's comforting to know I can connect with a guide who understands the Bible so well."</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        <section id="services" className="w-full py-24">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-serif text-center mb-12 text-black">What We Offer</h2>
            <div className="grid gap-8 md:grid-cols-3">
              <Card className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow">
                <CardContent className="p-6">
                  <div className="w-12 h-12 bg-sky-100 rounded-full flex items-center justify-center mb-4">
                    <Heart className="w-6 h-6 text-sky-600" />
                  </div>
                  <div className="text-sm font-medium text-black mb-2">GUIDANCE</div>
                  <h3 className="text-2xl font-serif mb-3 text-black">Relationship Guidance</h3>
                  <p className="text-black">Get heartfelt advice for life's complicated moments.</p>
                </CardContent>
              </Card>

              <Card className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow">
                <CardContent className="p-6">
                  <div className="w-12 h-12 bg-sky-100 rounded-full flex items-center justify-center mb-4">
                    <Book className="w-6 h-6 text-sky-600" />
                  </div>
                  <div className="text-sm font-medium text-black mb-2">CLARITY</div>
                  <h3 className="text-2xl font-serif mb-3 text-black">Faith and Life Questions</h3>
                  <p className="text-black">
                    Find direction and clarity for your faith and life decisions, guided by extensive Biblical knowledge.
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow">
                <CardContent className="p-6">
                  <div className="w-12 h-12 bg-sky-100 rounded-full flex items-center justify-center mb-4">
                    <PrayingHands className="w-6 h-6 text-sky-600" />
                  </div>
                  <div className="text-sm font-medium text-black mb-2">SUPPORT</div>
                  <h3 className="text-2xl font-serif mb-3 text-black">Personal Prayer</h3>
                  <p className="text-black">Need someone to pray with you? We're here to share a moment of reflection.</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="w-full py-24">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-serif text-center mb-12 text-black">How It Works</h2>
            <div className="grid gap-8 md:grid-cols-3">
              <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 bg-sky-100 rounded-full flex items-center justify-center mb-4">
                  <PhoneCall className="w-8 h-8 text-sky-600" />
                </div>
                <h3 className="text-xl font-serif text-black mb-2">Call DivineLine</h3>
                <p className="text-black">Dial our number to connect with a friendly guide.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 bg-sky-100 rounded-full flex items-center justify-center mb-4">
                  <MessageCircle className="w-8 h-8 text-sky-600" />
                </div>
                <h3 className="text-xl font-serif text-black mb-2">Share Your Thoughts</h3>
                <p className="text-black">Talk openly about what's on your mind.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 bg-sky-100 rounded-full flex items-center justify-center mb-4">
                  <Feather className="w-8 h-8 text-sky-600" />
                </div>
                <h3 className="text-xl font-serif text-black mb-2">Receive Guidance</h3>
                <p className="text-black">
                  Get thoughtful advice or just a listening ear, enriched by deep Biblical understanding.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="cta" className="w-full py-24">
          <div className="container mx-auto px-4 md:px-6 text-center">
            <h2 className="text-3xl font-serif text-black mb-4">Take the First Step Towards Peace</h2>
            <p className="text-black mb-8 max-w-2xl mx-auto">
              A caring voice is just a call away. DivineLine is here to lighten your load.
            </p>
            <Button size="lg" className="bg-sky-500 hover:bg-sky-600 text-white">Call Now</Button>
          </div>
        </section>
      </main>

      <footer className="text-black py-12">
        <div className="container mx-auto px-4 md:px-6">
          <div>
            <h2 className="text-2xl font-serif mb-4 text-black">Contact Us</h2>
            <p className="text-black">Questions? Email us at support@divineline.com</p>
          </div>
          <div className="mt-8 pt-8 border-t border-sky-800 text-center">
            <p className="text-black">&copy; 2024 DivineLine. All rights reserved.</p>
          </div>
        </div>
      </footer>

      {showAuthFlow && <AuthFlow />}
    </div>
  );
}

export default App;
