'use client'

import { createClient } from '@supabase/supabase-js'
import { useEffect, useState } from 'react'
import { Button } from "../../components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card"
import { Input } from "../../components/ui/input"

// Initialize the Supabase client
const supabase = createClient('https://gcszpsmitcnzvbbxcleq.supabase.co', process.env.REACT_APP_SUPABASE_ANON_KEY)

export default function AuthFlow() {
  const [session, setSession] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  async function signUp() {
    const { error } = await supabase.auth.signUp({
      email,
      password,
    })
    if (error) alert('Error signing up: ' + error.message)
    else alert('Check your email for the confirmation link!')
  }

  async function signIn() {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    })
    if (error) alert('Error signing in: ' + error.message)
  }

  async function signOut() {
    const { error } = await supabase.auth.signOut()
    if (error) alert('Error signing out: ' + error.message)
  }

  return (
    <Card className="w-[350px]">
      <CardHeader>
        <CardTitle>Supabase Auth Flow</CardTitle>
        <CardDescription>Sign up or sign in to access protected content.</CardDescription>
      </CardHeader>
      <CardContent>
        {!session ? (
          <div className="space-y-4">
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="flex justify-between">
              <Button onClick={signUp}>Sign Up</Button>
              <Button onClick={signIn}>Sign In</Button>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <ProtectedComponent />
            <Button onClick={signOut} className="w-full">Sign Out</Button>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

function ProtectedComponent() {
  return (
    <div className="p-4 bg-green-100 rounded-md">
      <h2 className="text-lg font-semibold text-green-800">Protected Content</h2>
      <p className="text-green-700">This content is only visible to authenticated users.</p>
    </div>
  )
}